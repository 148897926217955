import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uploadSignature } from "../../redux/actions/webqueue";
import dataURLtoBlob from "../../assets/plugins/blueimp-canvas-to-blob/js/canvas-to-blob";
import { Notify } from "../../helpers/notification";
import { useTranslation } from "react-i18next";

const Agreement = (props) => {
  const { form, setForm, mainLoading, hideSave, handleNavigationForOffice } = props;
  const { _id } = useParams();
  const dispath = useDispatch();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef();
  const {t} = useTranslation();

  const handleSubmit = async (navigate=false) => {
    setLoading(true);
    const tempError = { ...errors };
    if (!canvasRef || !canvasRef.current || canvasRef.current.isEmpty()) {
      tempError.Signature_Applicant = "Signature is required.";
    } else {
      delete tempError.Signature_Applicant;
    }
    setErrors(tempError);
    if (Object.entries(tempError).length) {
      setLoading(false);
      return;
    }
    const signature = dataURLtoBlob(canvasRef?.current?.toDataURL("image/png"));
    try {
      if(_id){
        const formdata = new FormData();
        formdata.append(
          "Signature_Applicant",
          signature
          );
        await dispath(uploadSignature(_id, formdata));
        Notify("success", "Details Saved!");
       }
      if(navigate){
       props.handleNavigationForOffice ? props.handleNavigationForOffice("documents", null, signature) :  props.handleNavigation("documents");
      }
    } catch (error) {
      Notify("error", "Something went wrong, Please try again later!");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const renderAgreement = () => {
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <h3>{t('tenantApp.agreement')}</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>
            {t('tenantApp.agreement_.description1')}
            </p>
            <p>
            {t('tenantApp.agreement_.description1_1')}
            </p>
            <p>
            {t('tenantApp.agreement_.description2')}
            </p>
            <p>
            {t('tenantApp.agreement_.description3')}
            </p>
            <p>
              {" "}
              <strong>
              {t('tenantApp.agreement_.description4')}
              </strong>
            </p>
            <p>
            {t('tenantApp.agreement_.description5')}
            </p>
            <p>
              {t("tenantApp.agreement_.declaration1", {
                name: form["Name_First"] + " " + form["Name_Last"],
                "company_name": "Ania Management",
                "link": "TenantReports.com"
              })}
            </p>
            <p>
            {t("tenantApp.agreement_.declaration2")}
            </p>
            <p className="text-center">
              <u>{t("tenantApp.agreement_.additional_comment")}:</u>
            </p>
            <p className="text-center">
              <u>
              {t("tenantApp.agreement_.additional_comment1")}
              </u>
            </p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-7 ">
            <p>
              <strong> {t("tenantApp.agreement_.sign_here")}*</strong>
              <button
                className="btn btn-sm btn-secondary float-right"
                onClick={() => canvasRef?.current?.clear()}
              >
                 {t("tenantApp.agreement_.clear")}
              </button>
            </p>
            <SignatureCanvas
              ref={canvasRef}
              backgroundColor="#FFFFFF"
              canvasProps={{
                style: {
                  width: "100%",
                  height: 250,
                  border: `1px solid ${
                    errors?.Signature_Applicant ? "red" : "#d5dbe0"
                  }`,
                },
              }}
              style={{ widh: "200%" }}
              dotSize={1}
              minWidth={1}
              maxWidth={1}
              minDistance={1}
              onEnd={() => {
                const tempError = { ...errors };
                delete tempError.Signature_Applicant;
                setErrors(tempError);
              }}
            />
            <span className="text-danger">{errors?.Signature_Applicant}</span>
          </div>
        </div>
      </>
    );
  };

  const renderNavigationButton = () => {
    return (
      <>
        <div className="row mt-4 justify-content-end">
          <div className="col-6 col-md-4 col-lg-2">
            <button
              type="button"
              className="btn btn-secondary btn-block"
              onClick={() => {
                props.handleNavigation("previous");
              }}
              disabled={loading || mainLoading}
            >
              {t('tenantApp.navigation.previous')}
              {loading || mainLoading ? (
                <span className="spinner-border spinner-border-sm float-right text-black"></span>
              ) : null}
            </button>
          </div>
          {!hideSave ? <div className="col-4 col-lg-2">
            <button
              type="button"
              onClick={() => {
                handleSubmit(false);
              }}
              className="btn btn-success btn-block"
              disabled={loading}
            >
              {t('tenantApp.navigation.save')}
              {loading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>: null }
          <div className="col-6 col-md-4 col-lg-2">
            <button
              type="submit"
              className="btn btn-warning btn-block"
              onClick={() => {
                handleSubmit(true);
              }}
              disabled={loading || mainLoading}
            >
              {t('tenantApp.navigation.next')}
              {loading || mainLoading ? (
                <span className="spinner-border spinner-border-sm float-right"></span>
              ) : null}
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {renderAgreement()}

      {renderNavigationButton()}
    </>
  );
};

export default Agreement;
