import React, { useState, useEffect, useContext } from "react";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import OnBoardTenantFromOffice from "./OnBoardTenantFromOffice";
import { PageSettings } from "../../config/page-settings";
import { verifyRecaptchaAction } from "../../redux/actions/recaptcha";
import { useDispatch } from "react-redux";
import recaptchaImage from "../../assets/img/reCAPTCHA-logo@2x.png";

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const RecaptchaVerification = ({ onVerify }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isVerifying, setIsVerifying] = useState(true);
  const [error, setError] = useState(null);
  const context = useContext(PageSettings);
  const dispatch = useDispatch();

  useEffect(() => {
    const verifyRecaptcha = async () => {
      if (!executeRecaptcha) {
        setError("reCAPTCHA failed to load.");
        return;
      }

      try {
        const token = await executeRecaptcha("onboard_tenant");
        console.log({ token });
        const response = await dispatch(verifyRecaptchaAction({ token }));
        if (response?.success) {
          onVerify(true);
        } else {
          setError(response?.msg);
        }
      } catch (err) {
        setError("An error occurred while verifying reCAPTCHA.");
      } finally {
        setIsVerifying(false);
      }
    };

    verifyRecaptcha();
  }, [executeRecaptcha, onVerify]);

  useEffect(() => {
    context.handleSetPageSidebar(false);
    context.handleSetPageHeader(false);
  }, []);

  if (isVerifying) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center flex-column">
        <img src={recaptchaImage} width={100} />
        <p className="mt-3">Verifying your access... This won't take long!</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <p>{error}</p>
      </div>
    );
  }

  return null;
};

const OnboardTenantWrapper = () => {
  const [verified, setVerified] = useState(false);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
      {verified ? (
        <OnBoardTenantFromOffice />
      ) : (
        <RecaptchaVerification onVerify={setVerified} />
      )}
    </GoogleReCaptchaProvider>
  );
};

export default OnboardTenantWrapper;
