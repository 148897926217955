import { Notify } from "../../helpers/notification";
import Api from "../api/api";

export const verifyRecaptchaAction = (payload) => async (dispatch) => {
    try {
        const resposne = await Api.post(`api/recaptcha/verify`, payload);
        return resposne?.data?.data;
    } catch (error) {
        console.error(error);
        Notify("danger", error.message);
        throw error
      }
};
